import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors } from '@hultafors/snickers/helpers';

interface SliderBlockStyledProps {
  $src?: string;
  $dark?: boolean;
  $active?: boolean;
}
export const SliderBlockStyled = styled.div`
  position: relative;

  .swiper {
    width: 100%;
    overflow-x: hidden;
  }

  .swiper-wrapper {
    display: flex;

    > div {
      min-width: 100%;
    }
  }
`;

const NavigatorBtnShared = css`
  position: absolute;
  width: 100%;
  z-index: 2;
  display: flex;
`;

export const ArrowNavigator = styled.div`
  position: absolute;
  top: 50%;
  width: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  pointer-events: none;



  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    ${NavigatorBtnShared}
    justify-content: space-between;
    inline-size: calc(100% - 1.5em);
  }
`;

export const DotNavigator = styled.div`
  ${NavigatorBtnShared}
  bottom: 56px;
  display: flex;
  justify-content: space-between;
`;

export const DotNavigatorInner = styled.div`
  display: flex;
  margin-inline: auto;
`;

export const SwiperContainer = styled.div``;

export const SwiperWrapper = styled.div``;

const ButtonShared = css<SliderBlockStyledProps>`
  cursor: pointer;
  background: none;
  border: none;
  pointer-events: auto;
`;

export const ArrowBtn = styled.button<SliderBlockStyledProps>`
  ${ButtonShared}
  width: 46px;
  height: 46px;

  &:first-child {
    transform: rotate(90deg);
  }

  &:last-child {
    transform: rotate(-90deg);
  }

  &:first-child:active {
    transform: rotate(90deg) scale(0.96);
  }

   &:last-child:active {
    transform: rotate(-90deg) scale(0.96);
  }

  svg {
    filter: drop-shadow(0 3px 2px rgb(0 0 0 / 70%));
      inline-size: 100%;
      block-size: 100%;

    path {
      fill: ${colors.white};
    }
  }
`;

export const SeoTitle = styled.h1`
  position: absolute;
  left: -10000px;
`;

export const DotBtn = styled.button<SliderBlockStyledProps>`
  ${ButtonShared}

  span {
    display: block;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background: ${colors.gray2};

    &:hover {
      background: ${colors.gray4};
    }

    ${({ $active }) =>
      $active
      && css`
        pointer-events: none;
        background: ${colors.white};

        &:hover {
          background: ${colors.white};
        }
      `}
  }
`;
